import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useStoreon } from 'storeon/react'
import classNames from 'classnames'
import { ReactComponent as PinImage } from '@assets/pin.svg'
import { SidebarDialog } from '../SidebarDialog'
import { styles, shipmentOptionsStyles } from './ShipList.module.css'

const ShipList = ({ shipmentOptions, shipmentOptionId, setShipmentOptionId }) => {
  const [changingShipmentMethod, setChangingShipmentMethod] = useState(false)
  const { app } = useStoreon('app')

  const shipmentSelected = shipmentOptions.find(({ id }) => id === shipmentOptionId)

  const other = (instructions, cost) => (
    <div className="instructions">
      <h6>
        Costo:
        {' '}
        {cost}
      </h6>
      {instructions}
    </div>
  )

  if (!shipmentSelected) {
    return null
  }

  // const store = app.stores[app.current.storeIndex]
  const location = app.stores[app.current.storeIndex].locations[app.current.locationIndex]

  return (
    <div className={classNames(styles, {
      'without-address': ['Pickup', 'Digital'].includes(shipmentSelected.name),
    })}
    >
      <h1>Información de entrega:</h1>
      <div
        className={`selectedShip ${shipmentOptions.length > 1 ? 'hasOptions' : ''}`}
        onClick={() => shipmentOptions.length > 1 && setChangingShipmentMethod(true)}
      >
        <div className="label">
          {shipmentSelected.name}
        </div>
        <div className="price">
          $
          {shipmentSelected.baseCost.toFixed(2)}
        </div>
      </div>
      {
        shipmentSelected.shipmentOptionType === 'PICKUP' || shipmentSelected.originAddress ? (
          <a
            className="originAddress"
            target="_blank"
            href={location.coordinates.latitude && location.coordinates.longitude
              ? `https://maps.google.com/maps?z=12&t=m&q=loc:${location.coordinates.latitude}+${location.coordinates.longitude}`
              : '#'}
            rel="noreferrer"
          >
            {shipmentSelected.name === 'Pickup'
              ? `Recoger en: ${location.address || ''}`
              : shipmentSelected.originAddress || ''}
            <PinImage />
          </a>
        ) : null
      }
      <SidebarDialog show={changingShipmentMethod} onClose={() => setChangingShipmentMethod(false)}>
        <ul className={shipmentOptionsStyles}>
          {shipmentOptions.map((pay) => (
            <li key={pay.id}>
              <label>
                <div className="input">
                  <input
                    type="radio"
                    checked={shipmentOptionId === pay.id}
                    onChange={() => setShipmentOptionId(pay.id)}
                  />
                </div>
                <span>{pay.name}</span>
              </label>
              <div
                className={classNames('accordeon', {
                  hidden: shipmentOptionId !== pay.id,
                })}
              >
                {other(
                  pay.name === 'Pickup'
                    ? `Recoger en: ${location.address || ''}`
                    : pay.originAddress || '',
                  `$${pay.baseCost.toFixed(2)}`,
                )}
              </div>
            </li>
          ))}
        </ul>
      </SidebarDialog>
    </div>
  )
}

ShipList.propTypes = {
  shipmentOptions: PropTypes.arrayOf(
    PropTypes.shape({
      shipmentOptionId: PropTypes.number,
    }),
  ).isRequired,
}

export default ShipList

import { createStoreon } from 'storeon'
import { routerNavigate, routerChanged } from '@storeon/router'
import { persistState } from '@storeon/localstorage'
// import { storeonDevtools } from 'storeon/devtools'
import { datadogRum } from '@datadog/browser-rum'

// add other state fragments here
import router from './router'
import user from './user'
import cart from './cart'
import app from './app'
import track from './track'

const store = createStoreon([
  router,
  user,
  cart,
  app,
  track,
  // import.meta.env.DEV && storeonDevtools,
  persistState(['user', 'cart']),
])
export default store

// Other utility methods:

const navigate = (url) => {
  datadogRum.startView(url)

  const mountPoint = store.get().app?.id ? `/${store.get().app?.id}` : ''
  store.dispatch(routerNavigate, `${mountPoint ?? ''}${url}`)
}

const getCart = (storeId) => store.get().cart?.[storeId]?.[new Date().toJSON().slice(0, 10)] ?? []

export { navigate, getCart }

/* eslint-disable react/jsx-props-no-spreading */
import React, { Suspense } from 'react'
import { styles } from './Modal.module.css'

const withModal = (Component, returnTo, props) => (
  <div
    className={styles}
  >
    <div className="content">
      <Suspense fallback={null}>
        {Component ? <Component {...props} isModal={returnTo} /> : null}
      </Suspense>
    </div>
  </div>
)
export default withModal

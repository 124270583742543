/* eslint-disable react/jsx-props-no-spreading */
import React, { Suspense } from 'react'
import { Loading } from '@hb/web-components'
import { styles } from './Empty.module.css'

const empty = (Component, props) => (
  <div className={styles}>
    <Suspense fallback={<Loading />}>
      {Component ? <Component {...props} /> : null}
    </Suspense>
  </div>
)

export default empty

import React, { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'

import { styles } from './SidebarDialog.module.css'

const SidebarDialog = ({ children, show, onClose }) => (
  <Transition.Root show={show} as={Fragment}>
    <Dialog as="div" className={styles} onClose={onClose}>
      <Transition.Child
        as={Fragment}
        enter="bg-enter"
        enterFrom="bg-enterFrom"
        enterTo="bg-enterTo"
        leave="bg-leave"
        leaveFrom="bg-leaveFrom"
        leaveTo="bg-leaveTo"
      >
        <div className="dialog-bg" />
      </Transition.Child>

      <div className="sidebar">
        <Transition.Child
          as={Fragment}
          enter="sb-enter"
          enterFrom="sb-enterFrom"
          enterTo="sb-enterTo"
          leave="sb-leave"
          leaveFrom="sb-leaveFrom"
          leaveTo="sb-leaveTo"
        >
          <Dialog.Panel className="sidebar-dialog">
            <Transition.Child
              as={Fragment}
              enter="bg-enter"
              enterFrom="bg-enterFrom"
              enterTo="bg-enterTo"
              leave="bg-leave"
              leaveFrom="bg-leaveFrom"
              leaveTo="bg-leaveTo"
            >
              <div className="close-button">
                <button
                  type="button"
                  onClick={onClose}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="#000" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000" width="50px" height="50px" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </Transition.Child>
            <div className="sidebar-content">
              {children}
            </div>
          </Dialog.Panel>
        </Transition.Child>
        <div className="w-14 flex-shrink-0" aria-hidden="true">
          {/* Force sidebar to shrink to fit close icon */}
        </div>
      </div>
    </Dialog>
  </Transition.Root>
)

export default SidebarDialog

import React, { useEffect, useState } from 'react'
import { useStoreon } from 'storeon/react'
import { useManualQuery } from 'graphql-hooks'
import { navigate } from '@store'
import Error from '@error'
import QueryApp from '@queries/app.gql'

import { ModalBottom } from '../ModalBottom'

import { styles, menuStyles } from './Header.module.css'

const Header = ({ appId, storeId }) => {
  const [showModal, setShowModal] = useState(false)
  const { dispatch, app, user } = useStoreon('app', 'user')
  const [getApp, { loading, error, data: appData }] = useManualQuery(QueryApp)

  const store = app.stores?.[app.current.storeIndex] ?? {}

  useEffect(() => {
    if (!app.type) { // only request if it has not been requested yet
      (async () => {
        const response = await getApp()
        if (!response.data?.app) {
          // this storefront does not exist
          window.location = '/404'
        }
      })()
    }
  }, [])

  useEffect(() => {
    if (appData?.app && appData.app.name) {
      // const storeIndex = storeId ? appData.app.stores.findIndex(({ id }) => id === storeId) : 0
      dispatch('app/set', {
        id: appId,
        ...appData.app,
        // currentStoreIndex: storeIndex,
      })
    }
  }, [appData, user.geo?.latitude])

  useEffect(() => {
    if (app.type) {
      if (storeId) {
        const storeIndex = app.stores.findIndex(({ id }) => id === storeId)
        if (storeIndex !== app.current.storeIndex) {
          dispatch('app/setStore', storeIndex)
        }
      }
    }
  }, [app, storeId])

  useEffect(() => {
    if (store.name) {
      document.getElementById('title').innerHTML = store.name
      document.getElementById('favicon').href = store.storeImageUrl
    }
  }, [store.name])

  if (loading) {
    return null
  }

  if (storeId) {
    const storeIndex = app.stores.findIndex(({ id }) => id === storeId)
    if (storeIndex !== app.current.storeIndex) {
      return null
    }
  }

  if (error) {
    return <Error graphQLError={error} />
  }

  return (
    <>
      <nav className={styles}>
        <div className="logo">
          <img src={store.storeImageUrl || '/images/n1co-icon.svg'} />
        </div>
        <span className="name">{store.name || 'n1co'}</span>
        <div className="dots" onClick={() => setShowModal(true)}>
          &bull;&nbsp;&bull;&nbsp;&bull;
        </div>
      </nav>
      {
        showModal ? (
          <ModalBottom onClose={() => setShowModal(false)}>
            <ul className={menuStyles}>
              {
                user.isAuthenticated ? (
                  <>
                    <li className="lone">
                      <div className="center">
                        <h1>
                          Bienvenido
                          {' '}
                          {user.userData.name}
                        </h1>
                        <h2>
                          Puedes agregar tus productos favoritos para recibir a la puerta de tu casa
                          o puedes pasar a recogerlos a la tienda.
                        </h2>
                      </div>
                    </li>
                    <li onClick={() => navigate(`/store/${store.id}/detail`)}>
                      <div className="left">
                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12.6641 7.33073H15.3307V9.9974H12.6641V7.33073ZM12.6641 12.6641H15.3307V20.6641H12.6641V12.6641ZM13.9974 0.664062C6.6374 0.664062 0.664062 6.6374 0.664062 13.9974C0.664062 21.3574 6.6374 27.3307 13.9974 27.3307C21.3574 27.3307 27.3307 21.3574 27.3307 13.9974C27.3307 6.6374 21.3574 0.664062 13.9974 0.664062ZM13.9974 24.6641C8.1174 24.6641 3.33073 19.8774 3.33073 13.9974C3.33073 8.1174 8.1174 3.33073 13.9974 3.33073C19.8774 3.33073 24.6641 8.1174 24.6641 13.9974C24.6641 19.8774 19.8774 24.6641 13.9974 24.6641Z" fill="#422B91" />
                        </svg>
                      </div>
                      <div className="center">
                        <h1>Información del comercio</h1>
                        <h2>Dirección, horarios y descripción</h2>
                      </div>
                      <div className="right">
                        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M0.77722 11.5287C0.62722 11.3453 0.548054 11.137 0.53972 10.9037C0.531387 10.6703 0.610553 10.4703 0.77722 10.3037L5.10222 5.97867L0.75222 1.62867C0.60222 1.47867 0.531387 1.2745 0.53972 1.01617C0.548054 0.757835 0.62722 0.553669 0.77722 0.403669C0.960553 0.220335 1.16472 0.132835 1.38972 0.141168C1.61472 0.149502 1.81055 0.237002 1.97722 0.403669L6.95222 5.37867C7.03555 5.47867 7.09805 5.5745 7.13972 5.66617C7.18139 5.75784 7.20222 5.862 7.20222 5.97867C7.20222 6.09534 7.18139 6.1995 7.13972 6.29117C7.09805 6.38284 7.03555 6.47867 6.95222 6.57867L2.00222 11.5287C1.83555 11.6953 1.63555 11.7745 1.40222 11.7662C1.16889 11.7578 0.960553 11.6787 0.77722 11.5287Z" fill="#422B91" />
                        </svg>
                      </div>
                    </li>
                    <li onClick={() => navigate('/logout')}>
                      <div className="left">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M17.5 17.1C17.3 16.9 17.2 16.6556 17.2 16.3667C17.2 16.0778 17.3 15.8444 17.5 15.6667L20.1667 13H9.5C9.21111 13 8.97222 12.9056 8.78333 12.7167C8.59444 12.5278 8.5 12.2889 8.5 12C8.5 11.7111 8.59444 11.4722 8.78333 11.2833C8.97222 11.0944 9.21111 11 9.5 11H20.1L17.4 8.3C17.2222 8.12222 17.1333 7.89444 17.1333 7.61667C17.1333 7.33889 17.2333 7.1 17.4333 6.9C17.6333 6.7 17.8722 6.6 18.15 6.6C18.4278 6.6 18.6667 6.7 18.8667 6.9L23.3 11.3333C23.4111 11.4444 23.4889 11.5556 23.5333 11.6667C23.5778 11.7778 23.6 11.9 23.6 12.0333C23.6 12.1667 23.5778 12.2889 23.5333 12.4C23.4889 12.5111 23.4111 12.6222 23.3 12.7333L18.9 17.1333C18.7222 17.3111 18.4944 17.4 18.2167 17.4C17.9389 17.4 17.7 17.3 17.5 17.1ZM2 24C1.46667 24 1 23.8 0.6 23.4C0.2 23 0 22.5333 0 22V2C0 1.46667 0.2 1 0.6 0.6C1 0.2 1.46667 0 2 0H10.7C10.9889 0 11.2278 0.0944446 11.4167 0.283333C11.6056 0.472222 11.7 0.711111 11.7 1C11.7 1.28889 11.6056 1.52778 11.4167 1.71667C11.2278 1.90556 10.9889 2 10.7 2H2V22H10.7C10.9889 22 11.2278 22.0944 11.4167 22.2833C11.6056 22.4722 11.7 22.7111 11.7 23C11.7 23.2889 11.6056 23.5278 11.4167 23.7167C11.2278 23.9056 10.9889 24 10.7 24H2Z" fill="#422B91" />
                        </svg>
                      </div>
                      <div className="center">
                        <h1>Cerrar Sesión</h1>
                      </div>
                      <div className="right">
                        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M0.77722 11.5287C0.62722 11.3453 0.548054 11.137 0.53972 10.9037C0.531387 10.6703 0.610553 10.4703 0.77722 10.3037L5.10222 5.97867L0.75222 1.62867C0.60222 1.47867 0.531387 1.2745 0.53972 1.01617C0.548054 0.757835 0.62722 0.553669 0.77722 0.403669C0.960553 0.220335 1.16472 0.132835 1.38972 0.141168C1.61472 0.149502 1.81055 0.237002 1.97722 0.403669L6.95222 5.37867C7.03555 5.47867 7.09805 5.5745 7.13972 5.66617C7.18139 5.75784 7.20222 5.862 7.20222 5.97867C7.20222 6.09534 7.18139 6.1995 7.13972 6.29117C7.09805 6.38284 7.03555 6.47867 6.95222 6.57867L2.00222 11.5287C1.83555 11.6953 1.63555 11.7745 1.40222 11.7662C1.16889 11.7578 0.960553 11.6787 0.77722 11.5287Z" fill="#422B91" />
                        </svg>
                      </div>
                    </li>
                  </>
                ) : (
                  <>
                    <li onClick={() => navigate(`/store/${store.id}/checkout`)}>
                      <div className="left">
                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11.0026 2.86927C12.5493 2.86927 13.8026 4.1226 13.8026 5.66927C13.8026 7.21594 12.5493 8.46927 11.0026 8.46927C9.45594 8.46927 8.2026 7.21594 8.2026 5.66927C8.2026 4.1226 9.45594 2.86927 11.0026 2.86927ZM11.0026 14.8693C14.9626 14.8693 19.1359 16.8159 19.1359 17.6693V19.1359H2.86927V17.6693C2.86927 16.8159 7.0426 14.8693 11.0026 14.8693ZM11.0026 0.335938C8.05594 0.335938 5.66927 2.7226 5.66927 5.66927C5.66927 8.61594 8.05594 11.0026 11.0026 11.0026C13.9493 11.0026 16.3359 8.61594 16.3359 5.66927C16.3359 2.7226 13.9493 0.335938 11.0026 0.335938ZM11.0026 12.3359C7.4426 12.3359 0.335938 14.1226 0.335938 17.6693V20.3359C0.335938 21.0693 0.935938 21.6693 1.66927 21.6693H20.3359C21.0693 21.6693 21.6693 21.0693 21.6693 20.3359V17.6693C21.6693 14.1226 14.5626 12.3359 11.0026 12.3359Z" fill="#422B91" />
                        </svg>
                      </div>
                      <div className="center">
                        <h1>Iniciar sesión</h1>
                        <h2>Ingresa a tu cuenta</h2>
                      </div>
                      <div className="right">
                        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M0.77722 11.5287C0.62722 11.3453 0.548054 11.137 0.53972 10.9037C0.531387 10.6703 0.610553 10.4703 0.77722 10.3037L5.10222 5.97867L0.75222 1.62867C0.60222 1.47867 0.531387 1.2745 0.53972 1.01617C0.548054 0.757835 0.62722 0.553669 0.77722 0.403669C0.960553 0.220335 1.16472 0.132835 1.38972 0.141168C1.61472 0.149502 1.81055 0.237002 1.97722 0.403669L6.95222 5.37867C7.03555 5.47867 7.09805 5.5745 7.13972 5.66617C7.18139 5.75784 7.20222 5.862 7.20222 5.97867C7.20222 6.09534 7.18139 6.1995 7.13972 6.29117C7.09805 6.38284 7.03555 6.47867 6.95222 6.57867L2.00222 11.5287C1.83555 11.6953 1.63555 11.7745 1.40222 11.7662C1.16889 11.7578 0.960553 11.6787 0.77722 11.5287Z" fill="#422B91" />
                        </svg>
                      </div>
                    </li>
                    <li onClick={() => navigate(`/store/${store.id}/detail`)}>
                      <div className="left">
                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12.6641 7.33073H15.3307V9.9974H12.6641V7.33073ZM12.6641 12.6641H15.3307V20.6641H12.6641V12.6641ZM13.9974 0.664062C6.6374 0.664062 0.664062 6.6374 0.664062 13.9974C0.664062 21.3574 6.6374 27.3307 13.9974 27.3307C21.3574 27.3307 27.3307 21.3574 27.3307 13.9974C27.3307 6.6374 21.3574 0.664062 13.9974 0.664062ZM13.9974 24.6641C8.1174 24.6641 3.33073 19.8774 3.33073 13.9974C3.33073 8.1174 8.1174 3.33073 13.9974 3.33073C19.8774 3.33073 24.6641 8.1174 24.6641 13.9974C24.6641 19.8774 19.8774 24.6641 13.9974 24.6641Z" fill="#422B91" />
                        </svg>
                      </div>
                      <div className="center">
                        <h1>Información del comercio</h1>
                        <h2>Dirección, horarios y descripción</h2>
                      </div>
                      <div className="right">
                        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M0.77722 11.5287C0.62722 11.3453 0.548054 11.137 0.53972 10.9037C0.531387 10.6703 0.610553 10.4703 0.77722 10.3037L5.10222 5.97867L0.75222 1.62867C0.60222 1.47867 0.531387 1.2745 0.53972 1.01617C0.548054 0.757835 0.62722 0.553669 0.77722 0.403669C0.960553 0.220335 1.16472 0.132835 1.38972 0.141168C1.61472 0.149502 1.81055 0.237002 1.97722 0.403669L6.95222 5.37867C7.03555 5.47867 7.09805 5.5745 7.13972 5.66617C7.18139 5.75784 7.20222 5.862 7.20222 5.97867C7.20222 6.09534 7.18139 6.1995 7.13972 6.29117C7.09805 6.38284 7.03555 6.47867 6.95222 6.57867L2.00222 11.5287C1.83555 11.6953 1.63555 11.7745 1.40222 11.7662C1.16889 11.7578 0.960553 11.6787 0.77722 11.5287Z" fill="#422B91" />
                        </svg>
                      </div>
                    </li>
                  </>
                )
              }
            </ul>
          </ModalBottom>
        ) : null
      }
    </>
  )
}

export default Header

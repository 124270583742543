import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { styles } from './Modal.module.css'

const Modal = ({ title, children, onClose }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [])

  const ref = useRef(null)

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      onClose()
    }
  }

  return (
    <div className={styles} onClick={handleClickOutside}>
      <main ref={ref}>
        <header>
          <span>{title}</span>
          <span onClick={onClose}>&times;</span>
        </header>
        {children}
      </main>
    </div>
  )
}

Modal.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default Modal

import React from 'react'
import { GraphQLClient, ClientContext } from 'graphql-hooks'
import { useStoreon } from 'storeon/react'
import { node } from 'prop-types'

const {
  H4B_GRAPHQL_URL: url,
} = h4benv

const GraphQLProvider = ({ children }) => {
  const { user, app } = useStoreon('user', 'app')

  const headers = {}

  if (app.id) {
    headers['X-App-Id'] = app.id
  }

  if (app.stores?.[app.current.storeIndex]?.locations?.[app.current.locationIndex]) {
    headers['X-Location'] = app.stores[app.current.storeIndex].locations[app.current.locationIndex].id
  }

  if (user?.geo?.longitude && user?.geo?.latitude) {
    headers['X-Geo-Longitude'] = user.geo.longitude
    headers['X-Geo-Latitude'] = user.geo.latitude
  }

  if (user?.accessToken) {
    headers.Authorization = `Bearer ${user.accessToken}`
  }

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const client = new GraphQLClient({ url, headers })

  return (
    <ClientContext.Provider value={client}>
      {children}
    </ClientContext.Provider>
  )
}

GraphQLProvider.propTypes = {
  children: node.isRequired,
}

export default GraphQLProvider

import React, { useState } from 'react'
import PropTypes from 'prop-types'

import {
  RadioList,
} from '@hb/web-components'

import { SidebarDialog } from '../SidebarDialog'

import {
  styles,
  noLocations,
  list,
} from './TablePicker.module.css'

const TablePicker = ({ deliveryLocations, deliveryLocation, setDeliveryLocation }) => {
  const [changingLocation, setChangingLocation] = useState(false)
  const selected = deliveryLocations.find((l) => l.code === deliveryLocation) || {}
  if (deliveryLocations.length === 0) {
    return (
      <div className={noLocations}>
        Se llevará tu pedido directo a tus manos
      </div>
    )
  }

  if (deliveryLocation === null && changingLocation === false) {
    return (
      <div className={`${noLocations} pick`} onClick={() => setChangingLocation(true)}>
        Selecciona tu ubicación
      </div>
    )
  }

  return (
    <div className={styles}>
      <div className="selectedAddress" onClick={() => setChangingLocation(true)}>
        {selected.name}
      </div>
      <SidebarDialog show={changingLocation} onClose={() => setChangingLocation(false)}>
        <div style={{ padding: '1rem' }}>
          <div className={list}>
            <div className="details">
              <p>{selected.name}</p>
            </div>
            <RadioList
              selectedID={selected.id}
              items={deliveryLocations.map((a) => ({
                id: a.code,
                name: a.name,
              }))}
              onItemSelect={(newSel) => {
                const add = deliveryLocations.find((addd) => addd.code === newSel.id)
                if (add.code) {
                  setDeliveryLocation(add.code)
                }
              }}
              onNewItem={null}
              onItemRemove={null}
            />
          </div>
        </div>
      </SidebarDialog>
    </div>
  )
}

TablePicker.propTypes = {
  deliveryLocations: PropTypes.arrayOf(PropTypes.shape(
    { id: PropTypes.number, name: PropTypes.string, code: PropTypes.string },
  )).isRequired,
  deliveryLocation: PropTypes.string.isRequired,
}

export default TablePicker

/* eslint-disable no-console */
import React from 'react'
import PropTypes from 'prop-types'

import { ReactComponent as ErrorIcon } from '@assets/error.svg'

import { styles } from './Error.module.css'

const Error = ({
  title,
  message,
  details,
  graphQLError,
}) => (
  <div className={styles}>
    <div className="container-image">
      <ErrorIcon />
    </div>
    <div className="container-text">
      <h1>{title}</h1>
      <h2>{message}</h2>
      { details ? (
        <details>
          {details}
          {(() => console.error(details))()}
        </details>
      ) : null }
      { graphQLError ? (
        <ul>
          {
            graphQLError.httpError?.body ? (
              JSON.parse(graphQLError.httpError.body).errors?.map(({ message: m }) => <li>{m}</li>)
            ) : (
              <li>{JSON.stringify(graphQLError, null, 4)}</li>
            )
          }
        </ul>
      ) : null }
    </div>
  </div>
)

Error.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  details: PropTypes.string,
}

Error.defaultProps = {
  title: 'Error inesperado',
  message: 'Ocurrió un error procesando tu transacción. Por favor intenta nuevamente.',
  details: '',
}

export default Error

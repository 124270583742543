import React from 'react'
import PropTypes from 'prop-types'

import { styles } from './Price.module.css'

const Price = ({ price, promoPrice, className }) => (
  <div className={`${styles} ${className}`}>
    {
      promoPrice ? (
        <div className="price">
          $
          {promoPrice.toFixed(2)}
          <div className="promoPrice">
            $
            {price.toFixed(2)}
          </div>
        </div>
      ) : (
        <div className="price">
          $
          {price.toFixed(2)}
        </div>
      )
    }
  </div>
)

Price.propTypes = {
  price: PropTypes.number.isRequired,
  promoPrice: PropTypes.number,
  className: PropTypes.string,
}

Price.defaultProps = {
  className: '',
  promoPrice: null,
}

export default Price

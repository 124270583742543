import { createRouter } from '@storeon/router'

const routes = [
  [
    '/',
    () => ({
      page: 'welcome',
      layout: 'two',
    }),
  ],
  [
    '/*/stores',
    (appId) => ({
      page: 'home',
      layout: 'empty',
      props: {
        appId,
      },
    }),
  ],
  [
    '/*/store/*',
    (appId, storeId) => ({
      page: 'store',
      layout: 'two',
      props: {
        appId,
        storeId,
      },
    }),
  ],
  [
    '/*/store/*/search',
    (appId, storeId) => ({
      page: 'search',
      layout: 'empty',
      props: {
        appId,
        storeId,
      },
    }),
  ],
  [
    '/*/store/*/product/*',
    (appId, storeId, productId) => ({
      page: 'store',
      layout: 'two',
      props: {
        appId,
        storeId,
      },
      modal: {
        page: 'product',
        title: 'Detalle del producto',
        returnTo: `/store/${storeId}`,
        returnComponent: 'store',
        props: {
          storeId,
          productId,
        },
      },
    }),
  ],
  [
    '/*/store/*/checkout',
    (appId, storeId) => ({
      page: 'checkout',
      layout: 'empty',
      props: {
        appId,
        storeId,
      },
    }),
  ],
  [
    '/*/store/*/cart',
    (appId, storeId) => ({
      page: 'cart',
      layout: 'empty',
      props: {
        appId,
        storeId,
      },
    }),
  ],
  [
    '/*/store/*/detail',
    (appId, storeId) => ({
      page: 'store-detail',
      layout: 'empty',
      props: {
        appId,
        storeId,
      },
    }),
  ],
  [
    '/*/store/*/wuhu/*',
    (appId, storeId, orderId) => ({
      page: 'wuhu',
      layout: 'empty',
      props: {
        appId,
        storeId,
        orderId,
      },
    }),
  ],
  [
    '/*/logout',
    () => ({
      page: 'logout',
    }),
  ],
  [
    '/login',
    () => ({
      page: 'login',
      layout: 'empty',
    }),
  ],
  [
    '/logout',
    () => ({
      page: 'logout',
    }),
  ],
  [
    '/404',
    () => ({
      page: 'notFound',
    }),
  ],
  [
    '/*',
    (appId) => ({
      page: 'welcome',
      layout: 'two',
      props: {
        appId,
      },
    }),
  ],
]

// export default createRouter(routes.map((r) => ([`${mountPoint ?? ''}${r[0]}`, r[1]])))
export default createRouter(routes)

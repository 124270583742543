query {
  app {
    name
    type
    checkoutSettings {
      actionLabel
      actionUrl
    }
    stores {
      id
      name
      description
      storeImageUrl
      storeBannerUrl
      storeBannerColor
      locations {
        id
        name
        address
        coordinates {
          latitude
          longitude
        }
        schedules {
          isActive
          day
          hours {
            startTime
            endTime
          }
        }
        isOpen
        canScheduleOrders
        isDefault
        shipmentOptions {
          id
          name
          baseCost
          originAddress
          isManaged
          acceptsCash
          shipmentOptionType
        }
      }
    }
    deliveryLocations {
      id
      name
      code
    }
  }
}
import { lazy } from 'react'

const componentsMap = new Map()

const getPageComponent = (moduleToLoad) => {
  if (componentsMap.has(moduleToLoad)) {
    return componentsMap.get(moduleToLoad)
  }

  let PageComponent = null
  switch (moduleToLoad) {
    case 'welcome':
      PageComponent = lazy(() => import('./Welcome/Welcome'))
      break
    case 'home':
      PageComponent = lazy(() => import('./Home/Home'))
      break
    case 'store':
      PageComponent = lazy(() => import('./Store/Store'))
      break
    case 'search':
      PageComponent = lazy(() => import('./Search/Search'))
      break
    case 'store-detail':
      PageComponent = lazy(() => import('./StoreDetail/StoreDetail'))
      break
    case 'product':
      PageComponent = lazy(() => import('./Product/Product'))
      break
    case 'checkout':
      PageComponent = lazy(() => import('./Checkout/Checkout'))
      break
    case 'cart':
      PageComponent = lazy(() => import('./Cart/Cart'))
      break
    case 'wuhu':
      PageComponent = lazy(() => import('./Wuhu/Wuhu'))
      break
    case 'logout':
      PageComponent = lazy(() => import('./Logout/Logout'))
      break
    default:
      PageComponent = lazy(() => import('./NotFound/NotFound'))
  }

  componentsMap.set(moduleToLoad, PageComponent)
  return PageComponent
}

export default getPageComponent

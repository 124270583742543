/* eslint-disable react/jsx-props-no-spreading */
import React, { Suspense } from 'react'
import { Loading } from '@hb/web-components'
import { Header } from '@components'
import { styles } from './Two.module.css'

const withHeader = (Component, page, props, appId = null) => (
  <div
    className={styles}
    key={`layout-${page}`}
  >
    <div className="header">
      <Header appId={appId} storeId={props?.storeId ? parseInt(props.storeId, 10) : null} />
    </div>
    <div className="content">
      <Suspense fallback={<Loading />}>
        {Component ? <Component {...props} /> : null}
      </Suspense>
    </div>
  </div>
)
export default withHeader

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useMutation } from 'graphql-hooks'
import {
  RadioList, Map, Button, Input,
} from '@hb/web-components'
import { useForm } from '@hb/web-hooks'
import { useStoreon } from 'storeon/react'

import AddressAddMutation from '@queries/addressAdd.gql'
import AddressDeleteMutation from '@queries/addressDelete.gql'

import { SidebarDialog } from '../SidebarDialog'
import { Modal } from '../Modal'

import {
  styles,
  mapStyles,
  formAddressStyles,
  noAddresses,
  list,
} from './AddressList.module.css'

const {
  H4B_MAPS_API_ID: mapsApiKey,
} = h4benv

const loadGoogleMaps = () => new Promise((resolve, reject) => {
  const script = document.createElement('script')
  script.id = 'maps-script'
  script.src = `https://maps.googleapis.com/maps/api/js?key=${mapsApiKey}&region=GT&libraries=places`
  script.async = true
  script.onload = () => resolve(window.google)
  script.onerror = () => reject(new Error('Google Maps script failed to load'))

  document.head.appendChild(script)
})

const AdressList = ({ userAddresses, addressId, setAddressId }) => {
  const schema = {
    addressName: 'required|string|min:0|max:100',
    addressPhone: 'string|min:0|max:100',
    addressDescription: 'required|string|max:2048',
    addressNote: 'string|max:2048',
    latitude: 'required|number',
    longitude: 'required|number',
  }
  const { user } = useStoreon('user')
  const form = useForm(schema, {
    addressPhone: user.userData?.phone,
  })
  const [changingAddress, setChangingAddress] = useState(false)
  const [wizard, setWizard] = useState(null)

  const [postAddAddress, gqlAdd] = useMutation(AddressAddMutation)
  const [postDeleteAddress, gqlDel] = useMutation(AddressDeleteMutation)
  // const [postUpdateAddress, gqlUpd] = useMutation(AddressUpdateMutation)

  const loading = gqlAdd.loading || gqlDel.loading

  const handlePinDrop = (address) => {
    if (address.marker?.lat && address.marker?.lng) {
      form.setValue('latitude', address.marker.lat)
      form.setValue('longitude', address.marker.lng)
      if (address.geocode) {
        form.setValue('addressDescription', address.geocode)
      }
    }
  }

  const handleModalClose = () => {
    setWizard(null)
    form.clear()
  }

  const handleAddAddress = async () => {
    if (!document.getElementById('maps-script')) {
      await loadGoogleMaps()
    }
    setChangingAddress(false)
    setWizard(0)
  }

  const handleMapAccept = () => {
    setWizard(1)
  }

  const handleAddrAccept = async () => {
    const {
      addressDescription,
      addressDescription2,
      addressName,
      addressNote,
      addressPhone,
      latitude,
      longitude,
    } = form.values

    const response = await postAddAddress({
      variables: {
        input: {
          addressName,
          addressDescription,
          addressLine2: addressDescription2,
          note: addressNote,
          phone: addressPhone,
          location: {
            latitude,
            longitude,
          },
        },
      },
    })

    if (response.data?.buyerShippingAddressCreate.success) {
      setAddressId(
        response.data.buyerShippingAddressCreate.id,
      )
    }
    handleModalClose()
  }

  const handleRemoveAddress = async () => {
    await postDeleteAddress({
      variables: {
        input: { id: addressId },
      },
    })
    setAddressId(userAddresses.find((add) => add.id !== addressId)?.id)
    setChangingAddress(false)
  }

  const selected = userAddresses.find((add) => add.id === addressId) || {}

  if (userAddresses.length === 0 && wizard === null) {
    return (
      <div className={noAddresses} onClick={handleAddAddress}>
        Agregar dirección de entrega
      </div>
    )
  }

  const defaultLat = user.geo?.latitude || 13.70971957580675
  const defaultLng = user.geo?.longitude || -89.20845549668567

  return (
    <div className={styles}>
      <div className="selectedAddress" onClick={() => setChangingAddress(true)}>
        {selected.name}
        :
        {' '}
        {selected.address}
      </div>
      <SidebarDialog show={changingAddress} onClose={() => setChangingAddress(false)}>
        <div style={{ padding: '1rem' }}>
          <div className={list}>
            <div className="details">
              <p>{selected.address}</p>
              <p>{selected.addressLine2}</p>
            </div>
            <RadioList
              selectedID={selected.id}
              items={userAddresses.map((a) => ({
                id: a.id,
                name: a.name,
                detail: `${a.address || ''} ${a.addressLine2 || ''}`,
              }))}
              onItemSelect={(newSel) => {
                const add = userAddresses.find((addd) => addd.id === newSel.id)
                if (add.id) {
                  setAddressId(add.id)
                }
              }}
              onNewItem={handleAddAddress}
              onItemRemove={handleRemoveAddress}
              addActionText="Agregar dirección"
            />
          </div>
        </div>
      </SidebarDialog>
      {wizard === 0 && (
      <Modal title="Agregar dirección" onClose={handleModalClose}>
        <div className={mapStyles}>
          <div className="map">
            <Map
              onPinDrop={handlePinDrop}
              withGeocoding
              defaultLat={defaultLat}
              defaultLng={defaultLng}
              defaultMarker={{
                lat: defaultLat,
                lng: defaultLng,
              }}
              withSearchBox
            />
          </div>
          <footer>
            <h3>¡Coloca el marcador en el punto de entrega!</h3>
            <Button
              onClick={handleMapAccept}
              htmlProps={{ disabled: !form.values.latitude }}
            >
              Aceptar
            </Button>
          </footer>
        </div>
      </Modal>
      )}
      {wizard === 1 && (
      <Modal title="Agregar dirección" onClose={handleModalClose}>
        <div className={formAddressStyles}>
          <Input
            name="addressName"
            required
            type="text"
            label="Nombre para la dirección"
            placeholder="Casa, oficina, universidad"
            onChange={form.handleChange}
            value={form.values.addressName}
            error={form.hasError(
              'addressName',
              'Error en el nombre de la dirección',
            )}
          />
          <Input
            name="addressPhone"
            type="text"
            label="Teléfono de contacto"
            onChange={form.handleChange}
            value={form.values.addressPhone}
            error={form.hasError(
              'addressPhone',
              'Error en el teléfono de la dirección',
            )}
          />
          <Input
            name="addressDescription"
            required
            type="textarea"
            label="Dirección exacta"
            onChange={form.handleChange}
            value={form.values.addressDescription}
            error={form.hasError(
              'addressDescription',
              'Error en la dirección',
            )}
          />
          <Input
            name="addressDescription2"
            type="text"
            label="Otros datos de la dirección"
            placeholder="# de apartamento, # de casa, # de oficina"
            onChange={form.handleChange}
            value={form.values.addressDescription2}
            error={form.hasError(
              'addressDescription2',
              'Error en los campos adicionales',
            )}
          />
          <Input
            name="addressNote"
            type="textarea"
            label="Notas de la entrega"
            onChange={form.handleChange}
            value={form.values.addressNote}
            error={form.hasError('addressNote', 'Notas')}
          />
          <Button
            onClick={handleAddrAccept}
            htmlProps={{ disabled: !form.values.latitude || !form.isValid }}
            loading={loading}
          >
            Aceptar
          </Button>
        </div>
      </Modal>
      )}
    </div>
  )
}

AdressList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  userAddresses: PropTypes.arrayOf(PropTypes.object),
}

AdressList.defaultProps = {
  userAddresses: [],
}

export default AdressList

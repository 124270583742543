const appStore = (store) => {
  store.on('@init', () => ({
    app: {
      id: '',
      name: 'n1co',
      type: '',
      stores: [],
      current: {
        storeIndex: 0,
        locationIndex: 0,
        deliveryIndex: 0,
      },
    },
  }))

  // you can provide a default store using currentStoreIndex and currentLocationIndex
  store.on('app/set', ({ app }, data) => (
    {
      app: {
        ...app,
        ...data,
        current: {
          storeIndex: data.currentStoreIndex || 0,
          locationIndex: data.currentLocationIndex || 0,
          deliveryIndex: data.currentDeliveryIndex || 0,
          // store: data.stores[data.currentStoreIndex || 0],
          // location: data.stores[data.currentStoreIndex || 0].locations[data.currentLocationIndex || 0],
        },
      },
    }
  ))

  store.on('app/setId', ({ app }, id) => (
    {
      app: {
        ...app,
        id,
      },
    }
  ))

  store.on('app/setStore', ({ app }, storeIndex) => (
    {
      app: {
        ...app,
        current: {
          storeIndex,
          locationIndex: 0,
          deliveryIndex: 0,
        },
      },
    }
  ))

  store.on('app/setLocation', ({ app }, locationIndex) => (
    {
      app: {
        ...app,
        current: {
          storeIndex: app.current.storeIndex,
          locationIndex,
          deliveryIndex: 0,
        },
      },
    }
  ))

  store.on('app/setDelivery', ({ app }, deliveryIndex) => (
    {
      app: {
        ...app,
        current: {
          storeIndex: app.current.storeIndex,
          locationIndex: app.current.locationIndex,
          deliveryIndex,
        },
      },
    }
  ))
}

export default appStore

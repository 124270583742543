mutation ($input: DeleteShippingAddressCommandInput) {
	buyerShippingAddressDelete(
		input: $input
	) {
		id
		success
		message
		userErrors {
			field
			message
		}
	}
}
import React, { Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { StoreContext } from 'storeon/react'
import store from '@store'
import { AuthProvider } from '@hooks'
import * as Sentry from '@sentry/react'
import { FullLoading } from '@hb/web-components'
import { datadogRum } from '@datadog/browser-rum'
import GraphQLProvider from './hooks/GraphQLProvider'

import Page from './pages'
import Error from './pages/Error/Error'

// reset css styles
import 'minireset.css'
import './styles/global.css'

const {
  H4B_DATADOG_APP_ID: DATADOG_APP_ID,
  H4B_DATADOG_CLIENT_TOKEN: DATADOG_CLIENT_TOKEN,
  H4B_DATADOG_SITE: DATADOG_SITE,
  H4B_DATADOG_SERVICE: DATADOG_SERVICE,
  H4B_SENTRY_DSN: SENTRY_DSN,
} = h4benv

const SentryPage = Sentry.withProfiler(Page, { name: 'SentryPage' })

// eslint-disable-next-line react/prop-types
const App = () => (
  <Sentry.ErrorBoundary fallback={<Error />} showDialog>
    <StoreContext.Provider value={store}>
      <AuthProvider>
        <GraphQLProvider>
          <Suspense fallback={<FullLoading />}>
            <SentryPage />
          </Suspense>
        </GraphQLProvider>
      </AuthProvider>
    </StoreContext.Provider>
  </Sentry.ErrorBoundary>
)

if (!import.meta.env.DEV) {
  datadogRum.init({
    applicationId: DATADOG_APP_ID,
    clientToken: DATADOG_CLIENT_TOKEN,
    site: DATADOG_SITE,
    service: DATADOG_SERVICE,
    sampleRate: 100,
    trackInteractions: true,
  })
  Sentry.init({
    environment: import.meta.env.DEV ? 'development' : 'production',
    dsn: SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', '127.0.0.1', /.*h4b\.dev.*/, /.*n1co\.shop.*/],
      }),
      new Sentry.Replay({
        // Additional SDK configuration goes in here, for example:
        maskAllText: false,
        blockAllMedia: false,
        maskAllInputs: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    beforeBreadcrumb(breadcrumb) {
      return (breadcrumb.category === 'xhr'
        && (breadcrumb.data.url.includes('datadog')
        || breadcrumb.data.url.includes('smartlook')))
        ? null
        : breadcrumb
    },
  })
}

const rootElement = document.getElementById('root')
const root = createRoot(rootElement)
root.render(<App />)

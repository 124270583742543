const productHash = (product) => `${product.product.id}-${JSON.stringify(product.activeModifiers)}-${product.note}`

const cartStore = (store) => {
  store.on('@init', () => ({
    cart: {
    },
  }))

  // cart will be stored as today's cart. If there is an old cart, it will be deleted
  store.on(
    'cart/addToCart',
    ({ cart }, { storeId, newProduct }) => {
      const date = new Date().toJSON().slice(0, 10)
      const oldCart = cart?.[storeId]?.[date] ?? []
      let cc = [...oldCart]
      // todo: add note to hash
      const hash = productHash(newProduct)
      const productInCart = cc.findIndex((oldProduct) => productHash(oldProduct) === hash)

      const copiesToAdd = newProduct.quantity || 1

      if (productInCart > -1) {
        if (!cc[productInCart].quantity) {
          // there was one, so now there will be 2
          cc[productInCart].quantity = copiesToAdd + 1
        } else {
          cc[productInCart].quantity += copiesToAdd
        }
      } else {
        cc = [...oldCart, newProduct]
      }

      return ({ cart: { ...cart, [storeId]: { [date]: cc } } })
    },
  )

  store.on(
    'cart/modifyQuantity',
    ({ cart }, { storeId, index, quantity }) => {
      const date = new Date().toJSON().slice(0, 10)
      const oldCart = [...cart?.[storeId]?.[date] ?? []]
      if (quantity > 0) {
        oldCart[index].quantity = quantity
      } else {
        oldCart.splice(index, 1)
      }
      return ({ cart: { ...cart, [storeId]: { [date]: [...oldCart] } } })
    },
  )

  store.on(
    'cart/clean',
    () => ({ cart: { } }),
  )
}

export default cartStore

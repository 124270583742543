mutation($input: CreateShippingAddressCommandInput) {
	buyerShippingAddressCreate(input: $input) {
		id
		success
		message
		userErrors {
			field
			message
		}
	}
}